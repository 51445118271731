// import ContactForm from "@components/forms/ContactForm"
import MailIcon from "@components/icons/MailIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { getWhatsAppURL, gtagReportConversion, parseHTML } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./ContactFomModule.scss"
import loadable from "@loadable/component"
import { ENQUIRY_FORMS } from "@components/forms/utils"
import { isEmpty } from "lodash"
import { getCTA } from "@components/TileBlock/TileBlock"

const FormModule = ({ module, className }) => {
  const { isTablet } = useDeviceMedia()

  const { email, phone, whatsapp } = useBrandInfo()

  if (!module) return null

  const { content, title, show_contact, form, stats, icon_stats, cta } = module

  let Form = null
  switch (form) {
    case "Book_a_Valuation":
      Form = loadable(() => import("@components/forms/ListYourPropertyForm"))
      break
    case "Send_us_your_CV":
      Form = loadable(() => import("@components/forms/SendCVForm"))
      break
    case ENQUIRY_FORMS.generalEnquiry:
      Form = loadable(() => import("@components/forms/EnquiryForm"))
      break
      case ENQUIRY_FORMS.propertyMangement:
        Form = loadable(() => import("@components/forms/EnquiryForm"))
        break
        case ENQUIRY_FORMS.partnerProgram:
          Form = loadable(() => import("@components/forms/EnquiryForm"))
          break
          case ENQUIRY_FORMS.mortagage:
            Form = loadable(() => import("@components/forms/EnquiryForm"))
            break
            case ENQUIRY_FORMS.snagging:
              Form = loadable(() => import("@components/forms/EnquiryForm"))
              break
              case ENQUIRY_FORMS.prism:
                Form = loadable(() => import("@components/forms/EnquiryForm"))
                break
                case ENQUIRY_FORMS.propertyLeasing:
                  Form = loadable(() => import("@components/forms/EnquiryForm"))
                  break
                  case ENQUIRY_FORMS.conveyancing:
                  Form = loadable(() => import("@components/forms/EnquiryForm"))
                  break
                  case ENQUIRY_FORMS.primestay:
                  Form = loadable(() => import("@components/forms/EnquiryForm"))
                  break
       
    default:
      Form = loadable(() => import("@components/forms/ContactForm"))
  }

  const CtaSection = show_contact ? (
    <div className="cta-section">
      <div className="cta-item">
        <div className="cta-icon">
          <WhatsAppIcon />
        </div>
        <div className="cta-content">
          <p className="cta-label">WhatsApp</p>
          <a
            className="cta-value"
            // href={getWhatsAppURL(whatsapp)}
            target="_blank"
            rel="noreferrer"
            id="openbot"
          >
            Click to WhatsApp
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="cta-item">
        <div className="cta-icon">
          <PhoneIcon />
        </div>
        <div className="cta-content phone-content">
          <p className="cta-label">Phone</p>
          <a className="cta-value" href={`tel:${phone}`} onClick={() => gtagReportConversion(`tel:${phone}`)}>
            {phone}
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="cta-item">
        <div className="cta-icon">
          <MailIcon />
        </div>
        <div className="cta-content">
          <p className="cta-label">Email</p>
          <a
            className="cta-value"
            href={`mailto:${email}`}
            target="_blank"
            rel="noreferrer"
          >
            {email}
          </a>
        </div>
      </div>
    </div>
  ) : null

  const CTA = getCTA(cta)

  return (
    <div
      className={clsx("contact-form-wrapper ", "section-p", className)}
      id={form}
    >
      <Container className={clsx("contact-form-container ")}>
        <div className="content-section">
          <h3 className="title">{title}</h3>
          {!isEmpty(stats) && (
            <div className="stats-section">
              {stats.map((stat) => (
                <div className="stat-item" key={stat.id}>
                  <div className="stat-title">{stat.title}</div>
                  <div className="stat-description">{stat.description}</div>
                </div>
              ))}
            </div>
          )}
          <div className="description">{parseHTML(content?.data?.content)}</div>
          {!isEmpty(icon_stats) && (
            <div className="icon-stats-section">
              {icon_stats.map((item) => (
                <div className="icon-stat" key={item.id}>
                  <img src={item?.image?.url} alt="" />
                  <p className="text">{item?.text}</p>
                </div>
              ))}
            </div>
          )}
          {!isTablet && CtaSection}
          {CTA}
        </div>
        <div className="form-section">
          <Form choose_form={form} />
        </div>
        {isTablet && CtaSection}
      </Container>
    </div>
  )
}

export default FormModule
